<div class="container-fluid">
  <!-- <div class="row justify-content-center">
    <div class="col-4"><h1>Credits</h1></div>
  </div> -->
  <div class="row justify-content-center">
    <div class="col-4">
      <h3>Exhibition curator</h3>
      <p>Prof. Daniele Pellacani (University of Bologna)</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-4">
      <h3>Head of /DH.arc centre</h3>
      <p>Prof. Francesca Tomasi (University of Bologna)</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-4">
      <h3>Design and development</h3>
      <p>Carlo Teo Pedretti (University of Bologna)</p>
      <p>Paolo Bonora (University of Bologna)</p>
    </div>
  </div>
</div>
