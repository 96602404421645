<div
  class="container-fluid adapted"
  style="background-color: rgb(240, 240, 240)"
>
  <!-- METTI PADDING AL CONTAINER FLUID E TOGLI CONTAINER DALLA NAVBAR -->
  <!-- <div class="container"> -->
  <ng-container *ngIf="loading$ | async">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
  </ng-container>

  <div class="row align-items-start">
    <div class="col-4"><h1>Opere</h1></div>
  </div>
  <div class="row justify-content-md-start">
    <div class="col-3 total-works">
      <p style="font-size: 1.2rem">
        <span style="font-weight: bold">Filtra i risultati</span>
      </p>
    </div>
    <div class="col-4 total-works">
      <p style="font-size: 1.2rem">
        <span style="font-weight: bold">{{ loadedItemsFiltered.length }}</span> Oggetti
        totali
      </p>
    </div>
    <div class="col-4">
      <select
        class="form-select selectpicker show-tick"
        aria-label="Default select example"
        (change)="switchSort()"
      >
        <option selected>Ordine alfabetico (A→Z)</option>
        <option value="1">Ordine alfabetico (Z→A)</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-2 filters-list">
      <!-- <h4 class="filters-title">Filtri</h4> -->

      <!-- <button class="accordion">Collection</button>
      <div class="panel">
        <ng-container *ngFor="let filter of filteredItems">
          <ng-container *ngIf="filter.label === 'relation'">
        <li class="filter-artist" [id]="filter.value" (click)="_addFilter(filter.value, filter.label)">{{filter.value}} <span class="badge bg-secondary">{{filter.count}}</span></li><br>
          </ng-container>
      </ng-container>
      </div> -->

      <button class="accordion">Autore</button>
      <div class="panel">
        <ng-container *ngFor="let filter of filteredItems">
          <ng-container *ngIf="filter.label === 'creator' && filter.count && filter.count > 0">
            <li
              class="filter-artist"
              [id]="filter.value"
              (click)="_addFilter(filter.value, filter.label)"
            >
              <i
              *ngIf="activeFilters.hasOwnProperty(filter.label) && 
                      activeFilters[filter.label].hasOwnProperty(filter.value) &&
                      activeFilters[filter.label][filter.value] == true" 
              class="fas fa-check" style="padding-right: 5px"></i>
              {{ filter.value }}
              <span class="badge bg-secondary">{{ filter.count }}</span>
            </li>
            <br />
          </ng-container>
        </ng-container>
      </div>

      <button class="accordion">Data</button>
      <div class="panel">
        <ng-container *ngFor="let filter of filteredItems">
          <ng-container *ngIf="filter.label === 'date' && filter.count && filter.count > 0">
            <li
              class="filter-date"
              [id]="filter.value"
              (click)="_addFilter(filter.value, filter.label)"
            >
              <i
              *ngIf="activeFilters.hasOwnProperty(filter.label) && 
                      activeFilters[filter.label].hasOwnProperty(filter.value) &&
                      activeFilters[filter.label][filter.value] == true" 
              class="fas fa-check" style="padding-right: 5px"></i>  
              {{ filter.value }}
              <span class="badge bg-secondary">{{ filter.count }}</span>
            </li>
            <br />
          </ng-container>
        </ng-container>
      </div>

      <button class="accordion">Tema</button>
      <div class="panel">
        <ng-container *ngFor="let filter of filteredItems">
          <ng-container *ngIf="filter.label === 'subject' && filter.count && filter.count > 0">
            <li
              class="filter-subject"
              [id]="filter.value"
              (click)="_addFilter(filter.value, filter.label)"
            >
              <i
              *ngIf="activeFilters.hasOwnProperty(filter.label) && 
                      activeFilters[filter.label].hasOwnProperty(filter.value) &&
                      activeFilters[filter.label][filter.value] == true" 
              class="fas fa-check" style="padding-right: 5px"></i>
              {{ filter.value }}
              <span class="badge bg-secondary">{{ filter.count }}</span>
            </li>
            <br />
          </ng-container>
        </ng-container>
      </div>

      <button class="accordion">Categoria</button>
      <div class="panel">
        <ng-container *ngFor="let filter of filteredItems">
          <ng-container *ngIf="filter.label === 'category' && filter.count && filter.count > 0">
            <li
              class="filter-artist"
              [id]="filter.value"
              (click)="_addFilter(filter.value, filter.label)"
            >
              <i
              *ngIf="activeFilters.hasOwnProperty(filter.label) && 
                      activeFilters[filter.label].hasOwnProperty(filter.value) &&
                      activeFilters[filter.label][filter.value] == true" 
              class="fas fa-check" style="padding-right: 5px"></i>
              {{ filter.value }}
              <span class="badge bg-secondary">{{ filter.count }}</span>
            </li>
            <br />
          </ng-container>
        </ng-container>
      </div>

      <button class="accordion">Tipologia</button>
      <div class="panel">
        <ng-container *ngFor="let filter of filteredItems">
          <ng-container *ngIf="filter.label === 'type' && filter.count && filter.count > 0">
            <li
              class="filter-type"
              [id]="filter.value"
              (click)="_addFilter(filter.value, filter.label)"
            >
              <i
                *ngIf="activeFilters.hasOwnProperty(filter.label) && 
                        activeFilters[filter.label].hasOwnProperty(filter.value) &&
                        activeFilters[filter.label][filter.value] == true" 
                class="fas fa-check" style="padding-right: 5px"></i>
              {{ filter.value }}
              <span class="badge bg-secondary">{{ filter.count }}</span>
            </li>
            <br />
          </ng-container>
        </ng-container>
      </div>

      <button class="accordion"><i class="fas fa-cubes" style="padding-right: 5px"></i>Collezione</button>
      <div class="panel">
        <ng-container *ngFor="let itemset of this.itemsets">
          <li
            class="filter-type"
            [id]="itemset['o:title']"
            (click)="_addFilter(itemset['o:title'], 'collection')"
          >
            <i
            *ngIf="activeFilters.hasOwnProperty('collection') && 
                    activeFilters['collection'].hasOwnProperty(itemset['o:title']) &&
                    activeFilters['collection'][itemset['o:title']] == true" 
            class="fas fa-check" style="padding-right: 5px"></i>
            {{ itemset['o:title'] }}
            <span class="badge bg-secondary">{{ itemset['dcterms:relation'].length }}</span>
          </li>
          <br />
        </ng-container>
      </div>

      <!-- <button class="accordion">Verses</button>
      <div class="panel">
        <p>Lorem ipsum...</p>
      </div> -->
    </div>

    <div class="col items-list">
      <div class="row no-padding-row">
        <div
          class="col-lg-auto active-filter-badge"
          *ngFor="let filterGroup of activeFilters | keyvalue"
        >
          <!-- filterGroup.key is badge.type -->
          <!-- filterGroupItem.key is badge.label -->
          <!-- filterGroupItem.value is whether the filter should be active or not -->
          <ng-container
            *ngFor="let filterGroupItem of filterGroup.value | keyvalue">
              <span
                *ngIf="filterGroupItem.value == true"
                class="badge badge-secondary filter-badge slide current"
                id="{{ filterGroupItem.key }}-badge"
                (click)="_removeFilter(filterGroupItem.key, filterGroup.key)"
                >
                {{ filterGroupItem.key }}
              </span>
          </ng-container>
        </div>
      </div>
      <div
        class="row no-padding-row collection-description"
        *ngIf="collectionDescription"
      >
        <div class="col-lg-auto">
          <p id="collection-description" (click)="_removeFilter(badge.label, badge.type)">
            <i class="fas fa-cubes" style="padding-right: 5px"></i>
            {{ collectionDescription }}
          </p>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-lg-auto item slide current"
          *ngFor="let item of loadedItemsFiltered"
        >
          <!-- PLACEHOLDER LOADER -->

          <!-- <ng-container *ngIf="!isArrayLoaded">
              <div class="card placeholder-card" style="width: 18rem">
                <div class="placeholder-item"></div>
                <div class="card-body">
                  <h5 class="card-title placeholder-title"></h5>
                  <br />
                  <div class="placeholder-metadata"></div>
                  <div class="placeholder-metadata"></div>
                  <div class="placeholder-metadata"></div>
                </div>
              </div>
            </ng-container> -->

          <!-- <ng-container *ngIf="isArrayLoaded"> -->
          <div class="link-wrapper">
            <a [routerLink]="['/work', item['o:id'], slugify(item['o:title'])]"
              ><img
                class="thumbnail-preview scale-image"
                [src]="item.thumbnail_display_urls.large"
            /></a>
            <p
              class="metadata-artist"
              *ngFor="let hit of item.metadata.creator"
            >
              {{ hit }}
            </p>
            <a
              class="link hover-1"
              [routerLink]="['/work', item['o:id'], slugify(item['o:title'])]"
              ><h3 class="metadata-title">{{ item.metadata.title }}</h3></a
            >
            <p class="metadata-date" *ngIf="item.metadata.date">
              {{ item.metadata.date }}
            </p>
            <p class="metadata-date" *ngIf="item.metadata.category">
              {{ item.metadata.category }}
            </p>
            <ng-container *ngIf="item.metadata.type?.length > 0">
              <span class="metadata metadata-date"
                >tipologia
                <ng-container *ngFor="let hit of item.metadata.type">
                  <span style="font-weight: bold">{{ hit }}</span>
                </ng-container>
              </span>
              <br />
            </ng-container>
            <ng-container *ngIf="item.metadata.subject?.length > 0">
              <span class="metadata metadata-date"
                >tema
                <ng-container *ngFor="let hit of item.metadata.subject; let i = index">
                  <span *ngIf="i != 0"> - </span>
                  <span style="font-weight: bold">{{ hit }}</span>
                </ng-container>
              </span>
              <br />
            </ng-container>
            <ng-container *ngIf="item.metadata.citation?.length > 0">
              <div>
                <!-- source -->
                <ng-container *ngFor="let hit of item.metadata.citation">
                  <a [href]="hit.link" target="_blank" class="metadata-source">
                    <i class="fas fa-book" style="padding-right: 5px"></i><span>{{ hit.label }}</span>
                  </a>
                </ng-container>
              </div>
              <br />
            </ng-container>
            <span class="metadata metadata-date" *ngIf="item.ref_count"
              >verses
              <span style="font-weight: bold">{{ item.ref_count }}</span></span
            >
          </div>
          <!-- </ng-container> -->
        </div>
      </div>
    </div>
  </div>

  <!-- </div> -->
</div>
