<!--Navbar -->
<div class="container-fluid navbar-header">
<div class="container navbar-resize">

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <!-- <div class="container-fluid"> -->
    <a class="navbar-brand" routerLink="/home">DRNOnline</a>
    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" data-bs-toggle="collapse" data-bs-target="#navbarNav"
    aria-controls="navbarSupportedContent" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active current" routerLink="/work">opere</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLinkActive="active current" routerLink="/collection">collections</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active current" routerLink="/map">mappa</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active current" routerLink="/timeline">timeline</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active current" routerLink="/library"><i>de rerum natura</i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active current" routerLink="/story">storie</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active current" routerLink="/credits">credits</a>
        </li>
      </ul>
    </div>
  <!-- </div> -->
</nav>

</div>
</div>
<!--/.Navbar -->
<!-- <div class="container-fluid" style="background-color: rgb(240, 240, 240);"> -->
<router-outlet></router-outlet>
<!-- </div> -->
<!-- Footer -->
<footer class="page-footer font-small unique-color-dark" style="background-color: #2d3439; color: whitesmoke">

  <!-- Footer Links -->
  <div class="container text-center" style="padding-top: 2rem;">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col">

        <!-- Content -->
        <h6 class="footer-title">About</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p style="font-size: 0.8rem;">DRNOnline is a digital exhibition that offers interactive and dynamic paths to discover a rich collection regarding the reception of Lucretius in contemporary art.</p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <!-- <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4"> -->

        <!-- Links -->
        <!-- <h6 class="text-uppercase font-weight-bold">Menu</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <a href="#!">items</a>
        </p>
        <p>
          <a href="#!"><i>de rerum natura</i></a>
        </p>
        <p>
          <a href="#!">timeline</a>
        </p>
        <p>
          <a href="#!">stories</a>
        </p>

      </div> -->
      <!-- Grid column -->

      <!-- Grid column -->
      <!-- <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4"> -->

        <!-- Links -->
        <!-- <h6 class="text-uppercase font-weight-bold">Links</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <a href="#!">Prova</a>
        </p>
        <p>
          <a href="#!">Prova</a>
        </p>
        <p>
          <a href="#!">Prova</a>
        </p>
      </div> -->
      <!-- Grid column -->

      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col">

        <!-- Links -->
        <h6 class="footer-title">Contacts</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p style="font-size: 0.8rem;">
          
          Università di Bologna<br>
          Dipartimento di Filologia Classica e Italianistica (<a href="https://ficlit.unibo.it/it" target="_blank">FICLIT</a>)<br>
          Via Zamboni 32<br>
          40126 Bologna, Italia</p>
        <p>
          <i class="fas fa-envelope mr-3"></i> <a style="font-size: 0.8rem;" href="dharc@live.unibo.it" target="_blank"> dharc@live.unibo.it</a></p>

      </div>

      <div class="col" style="align-self: 'center'; padding-bottom: 2.5rem;">

        <img src="./assets/img/unibologo.png" width="35%">

      </div>

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3" style="font-size: 0.8rem;">Copyright © <span id="copyright-year">{{year}}</span>: <a href="https://centri.unibo.it/dharc/en">/DH.arc</a>
    | <a routerLink="/credits">Credits</a> | <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/"><img alt="Licenza Creative Commons" style="border-width:0" src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png" /></a>
    <!-- <br />Quest'opera è distribuita con Licenza <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">Creative Commons Attribuzione - Non commerciale - Non opere derivate 4.0 Internazionale</a>. -->
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->