<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
</ng-container>

<div *ngIf="loadedStory" class="container-fluid adapted single-item">
  <div class="container-timeline">
    <div
      [style.display]="timelineActive ? 'block' : 'none'"
      id="timeline-embed"
      style="width: 100%; height: 600px"
    ></div>
  </div>

  <div [className]="!mapActive ? 'hidden' : ''">
    <ng-container *ngIf="tilesData">
      <div class="map-container">
        <div class="map-frame" [id]="tilesData.containerId"></div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="loadedRelatedItems">
    <div *ngIf="data" class="n7-image-viewer {{ data.classes || '' }}">
      <!-- OSD viewer -->
      <div
        [style.display]="viewerActive ? 'block' : 'none'"
        id="{{ data.viewerId }}"
        class="n7-image-viewer__viewer"
      ></div>

      <div
        *ngIf="!data.hideNavigation"
        class="n7-image-viewer__prev"
        id="n7-image-viewer-nav-prev"
      >
        <span class="n7-icon-angle-left"></span>
      </div>

      <div
        *ngIf="!data.hideNavigation"
        class="n7-image-viewer__next"
        id="n7-image-viewer-nav-next"
      >
        <span class="n7-icon-angle-right"></span>
      </div>
    </div>
  </ng-container>
  <!-- <hr /> -->
  <div class="container-fluid" style="max-width: 500px; padding-top: 20px">
    <div class="row justify-content-center">
      <div class="col-4 justify-content-center">
        <button class="btn btn-outline-dark btn-lg switch-view-buttons" (click)="switchView('imgviewer')">
          <i class="fas fa-camera"></i>
        </button>
      </div>
      <div class="col-4 justify-content-center">
        <button class="btn btn-outline-dark btn-lg switch-view-buttons" (click)="switchView('mapviewer')">
          <i class="fas fa-map"></i>
        </button>
      </div>
      <div class="col-4 justify-content-center">
        <button
          class="btn btn-outline-dark btn-lg switch-view-buttons"
          (click)="switchView('timelineviewer')"
        >
        <i class="fas fa-calendar"></i>
        </button>
      </div>
    </div>
    <hr/>

  </div>
  <div class="container-fluid metadata-box metadata">
    <div class="row justify-content-center">
      <div
        *ngIf="loadedStory && loadedStory['o:title']"
        class="col-8 metadata-title"
      >
        {{ loadedStory["o:title"] }}
      </div>
    </div>
    <div class="row justify-content-center description">
      <div
        *ngIf="loadedStory && loadedStory['dcterms:description']"
        class="col metadata-description"
      >
        {{ loadedStory["dcterms:description"][0]["@value"] }}

        <!-- TEI PUBLISHER -->

        <!-- <ng-container *ngFor="let doc of documents | keyvalue">
          <ng-container
            *ngIf="documents[doc.key].id.toString() == loadedStory['o:title']"
          >
            <div class="row">
              <div class="col-8 metadata-title" style="font-size: '18px'">
                {{ documents[doc.key].title.toString() }}
              </div>
            </div>

            <div class="row">
              

              <pb-page
                endpoint="https://teipublisher.com/exist/apps/tei-publisher"
                api-version="1.0.0"
              >
            
                <pb-document
                  [path]="documents[doc.key].document1"
                  odd="teipublisher"
                  id="document1"
                >
                </pb-document>
                <pb-document
                  [path]="documents[doc.key].document2"
                  odd="teipublisher"
                  id="document2"
                >
                </pb-document>

                <app-drawer-layout force-narrow="force-narrow" narrow="">
                  <app-header-layout>
                    <app-header
                      fixed="fixed"
                      data-template="browse:fix-links"
                      style="
                        transition-duration: 0ms;
                        transform: translate3d(0px, 0px, 0px);
                        left: 0px;
                        right: 0.399994px;
                      "
                    >
                      <app-toolbar
                        class="toolbar"
                        sticky="sticky"
                        style="transform: translate3d(0px, 0px, 0px)"
                      >

                        <pb-toggle-feature
                          name="highlight"
                          selector="tei-app,pb-popover,pb-highlight"
                          default="off"
                          action="disable"
                          emit="transcription"
                          subscribe="transcription"
                        >
                          <pb-i18n key="document.plain"
                            >Plain Reading View</pb-i18n
                          >
                        </pb-toggle-feature>
                        <pb-zoom
                          emit="transcription"
                          direction="in"
                          icon="icons:zoom-in"
                        ></pb-zoom>
                        <pb-zoom
                          emit="transcription"
                          direction="out"
                          icon="icons:zoom-out"
                        ></pb-zoom>
                        <pb-navigation
                          emit="transcription"
                          keyboard="left"
                          subscribe="transcription"
                          direction="backward"
                          unit="page"
                        >
                          <paper-icon-button
                            icon="icons:chevron-left"
                            role="button"
                            tabindex="0"
                            aria-disabled="false"
                          ></paper-icon-button>
                        </pb-navigation>
                        <pb-navigation
                          emit="transcription"
                          keyboard="right"
                          subscribe="transcription"
                          direction="forward"
                          unit="page"
                        >
                          <paper-icon-button
                            icon="icons:chevron-right"
                            role="button"
                            tabindex="0"
                            aria-disabled="false"
                          ></paper-icon-button>
                        </pb-navigation>

                        <pb-progress
                          subscribe="transcription"
                          indeterminate=""
                          bottom-item="bottom-item"
                          style="visibility: hidden"
                        ></pb-progress>
                      </app-toolbar>
                    </app-header>

                    <main class="content-body">
                      <pb-view
                        id="view1"
                        src="document1"
                        [xpath]="documents[doc.key].xpath1"
                        subscribe="transcription"
                        emit="transcription"
                      ></pb-view>
                      <pb-view
                        id="view2"
                        src="document2"
                        [xpath]="documents[doc.key].xpath2"
                        subscribe="transcription"
                        emit="letter"
                      >
                        <pb-param name="mode" value="commentary"></pb-param>
                      </pb-view>
                    </main>
                  </app-header-layout>
                </app-drawer-layout>
              </pb-page>

              
            </div>
          </ng-container>
        </ng-container> -->

        <!-- END TEI PUBLISHER -->

      </div>
      
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-5 col-12 related-resources-box">
        <div *ngIf="peopleEntities" class="people">
          <div class="people-box">
            <h4><i class="fas fa-users"></i> Persone</h4>
          </div>
          <div class="entities-box">
            <ng-container *ngFor="let person of peopleEntities">
              <div class="row">
                <div class="col">
                  —
                  <a
                    [href]="person.link"
                    class="text-decoration-none"
                    target="_blank"
                  >
                    {{ person.label }}
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-sm-5 col-12 related-resources-box">
        <div *ngIf="peopleEntities" class="people">
          <div class="people-box">
            <h4><i class="fas fa-cubes"></i> Risorse</h4>
          </div>
          <div class="entities-box">
            <ng-container *ngFor="let item of loadedRelatedItems">
              <div class="row">
                <div class="col">
                  —
                  <a
                    [routerLink]="[
                      '/work',
                      item['o:id'],
                      slugify(item['o:title'])
                    ]"
                    class="text-decoration-none"
                    target="_blank"
                  >
                    {{ item["o:title"] }}
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="timeline">
  <div id="timeline-embed" style="width: 100%; height: 600px"></div>
</div> -->
