<div class="container-fluid" style="background-color: rgb(240, 240, 240)">
  <div class="container n7-text-viewer" style="max-width: 1200px">
    <div class="row align-items-start">
      <div class="col-4">
        <h1 class="main-title"><i>De rerum natura</i></h1>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-10">
        <p>
          “Lucrezio ha avuto più modi per improntare la poesia di ogni tempo.
          […] Ma lucreziano è indubbiamente il senso unitario e universo della
          vita, più in particolare il fremito di erompente energia che si coglie
          nella profondità del vivente. Soprattutto in questo ultimo significato
          è probabile che una possibile grande poesia del futuro sia destinata a
          essere sempre più lucreziana”
        </p>
        <p>(Mario Luzi, 1974)</p>
      </div>
    </div>

    <!-- STATIC TEXT VIEWER -->

    <hr />
    <h3 class="main-title">DRN 1.1-16 (traduzione di Giuseppe Ungaretti)</h3>
    <div class="container">
      <div class="wrapper">
        <div class="text-input">
          <div spellcheck="false" class="from-text overflow">
            Aeneadum genetrix, hominum divomque voluptas, alma Venus, caeli
            subter labentia signa quae mare navigerum, quae terras frugiferentis
            concelebras, per te quoniam genus omne animantum concipitur visitque
            exortum lumina solis: te, dea, te fugiunt venti, te nubila caeli
            adventumque tuum, tibi suavis daedala tellus summittit flores, tibi
            rident aequora ponti placatumque nitet diffuso lumine caelum. nam
            simul ac species patefactast verna diei et reserata viget
            genitabilis aura favoni, aeriae primum volucris te, diva, tuumque
            significant initum perculsae corda tua vi. inde ferae pecudes
            persultant pabula laeta et rapidos tranant amnis: ita capta lepore
            te sequitur cupide quo quamque inducere pergis. denique per maria ac
            montis fluviosque rapacis frondiferasque domos avium camposque
            virentis omnibus incutiens blandum per pectora amorem efficis ut
            cupide generatim saecla propagent. quae quoniam rerum naturam sola
            gubernas nec sine te quicquam dias in luminis oras exoritur neque
            fit laetum neque amabile quicquam, te sociam studeo scribendis
            versibus esse, quos ego de rerum natura pangere conor Memmiadae
            nostro, quem tu, dea, tempore in omni omnibus ornatum voluisti
            excellere rebus.
          </div>
          <div spellcheck="false" readonly disabled class="to-text overflow">
            Genitrice degli Eneadi, voluttà degli uomini e degli dei, Venere che
            ristori, per te sotto i segni erranti del cielo, il mare navigato,
            le fruttifere terre si animano, poiché ogni specie viva il
            concepimento ti deve e l’ergersi suo a vedere luce di sole: mentre
            sopraggiungi venti e nuvole fuggono, Dea, e, industre, la terra
            leggiadri fiori fa sotto i tuoi piedi sbocciare, l’onda dei mari ti
            sorride, si placa il cielo e, di luce diffusa, risplende.
            Disserrato, insieme al dischiudersi dell’aspetto primaverile dei
            giorni, riprende vigore il soffio del Favonio, gli uccelli
            dell’aria, primi, Diva, danno, scosso il cuore dalla tua forza,
            notizia che arrivi. Fiere poi, mandrie balzano incontro alle pingui
            pasture, a nuoto passano torrenti: tanto, di te invaghito, ciascuno
            di seguirti brama dove l’induci a volgersi.
          </div>
        </div>
      </div>
    </div>

    <hr />
    <h3 class="main-title">DRN 4.1058-1072 (traduzione di Jolanda Insana)</h3>
    <div class="container">
      <div class="wrapper">
        <div class="text-input">
          <div spellcheck="false" class="from-text overflow">
            Haec Venus est nobis; hinc autemst nomen Amoris, hinc illaec primum
            Veneris dulcedinis in cor stillavit gutta et successit frigida cura;
            nam si abest quod ames, praesto simulacra tamen sunt illius et nomen
            dulce obversatur ad auris. sed fugitare decet simulacra et pabula
            amoris absterrere sibi atque alio convertere mentem et iacere umorem
            coniectum in corpora quaeque nec retinere semel conversum unius
            amore et servare sibi curam certumque dolorem; ulcus enim vivescit
            et inveterascit alendo inque dies gliscit furor atque aerumna
            gravescit, si non prima novis conturbes volnera plagis volgivagaque
            vagus Venere ante recentia cures aut alio possis animi traducere
            motus.
          </div>
          <div spellcheck="false" readonly disabled class="to-text overflow">
            Questa è Venere: è lei che muove amore, sua la dolce goccia che
            primamente stilla in cuore, ma freddo affanno presto segue toso
            perché assente l’amata, presente è la sua immagine e alle orecchie
            risuona il dolce nome. Ma occorre scacciare l’immagine, strapparsi
            alla pastura d’amore, altrove volgendo la mente in altro corpo
            riversando la piena di sperma senza trattenerlo né riservarlo a un
            solo amore per non riservare a sé affanni e sicuro dolore. L’ulcera
            si allarga e nutrita non si cicatrizza e di giorno in giorno cresce
            il furore e pesa la pena se con nuovi colpi non scancelli le antiche
            ferite e le più fresche non le curi andando a puttane o se altrove
            non volgi la passione della mente.
          </div>
        </div>
      </div>
    </div>

    <hr />
    <h3 class="main-title">DRN 1.540-550 (traduzione di Edoardo Sanguineti)</h3>
    <div class="container">
      <div class="wrapper">
        <div class="text-input">
          <div spellcheck="false" class="from-text overflow">
            at nunc, inter se quia nexus principiorum dissimiles constant
            aeternaque materies est, incolumi remanent res corpore, dum satis
            acris vis obeat pro textura cuiusque reperta. haud igitur redit ad
            nihilum res ulla, sed omnes discidio redeunt in corpora materiai.
          </div>
          <div spellcheck="false" readonly disabled class="to-text overflow">
            ora, poiché i nessi, tra gli atomi primi, stanno, tra loro,
            dissimili, e la materia è eterna, resistono, con il corpo incolume,
            le cose, finché una forza abbastanza violenta non le urti, adeguata
            alla struttura di ciascuna: non c’è cosa, dunque, che ritorna al
            nulla, ma tutte, per disgregazione, ritornano agli elementi corporei
            della materia:
          </div>
        </div>
      </div>
    </div>

    <hr />
    <h3 class="main-title">DRN 6.1138-1153 (traduzione di Jolanda Insana)</h3>
    <div class="container">
      <div class="wrapper">
        <div class="text-input">
          <div spellcheck="false" class="from-text overflow">
            Haec ratio quondam morborum et mortifer aestus finibus in Cecropis
            funestos reddidit agros vastavitque vias, exhausit civibus urbem.
            nam penitus veniens Aegypti finibus ortus, aeëra permensus multum
            camposque natantis, incubuit tandem populo Pandionis omni. inde
            catervatim morbo mortique dabantur. principio caput incensum fervore
            gerebant et duplicis oculos suffusa luce rubentes. sudabant etiam
            fauces intrinsecus atrae sanguine et ulceribus vocis via saepta
            coibat atque animi interpres manabat lingua cruore debilitata malis,
            motu gravis, aspera tactu. inde ubi per fauces pectus complerat et
            ipsum morbida vis in cor maestum confluxerat aegris, omnia tum vero
            vitai claustra lababant.
          </div>
          <div spellcheck="false" readonly disabled class="to-text overflow">
            Questo contagio, questa mortale ondata funestò un giorno le campagne
            d’Attica e spopolò le strade, spopolò la città. Scoppiato in fondo
            all’Egitto, da lì si mosse e attraversati tratti d’aria e piane di
            mare alla fine si posò sul popolo tutto di Atene che a cataste fu
            destinato al morbo e alla morte. In principio avevano la testa in
            fiamme e gli occhi rossi e lucidi. Dentro sudava sangue la gola nera
            e chiusa era la via della voce assiepata d’ulcere, e debilitata dal
            male, legata e rugosa, stillava sangue la lingua, interprete
            dell’anima. E quando poi la violenza del morbo giù per la gola
            inondava il petto arrivando proprio al cuore dolente dei malati
            crollavano tutti i baluardi della vita.
          </div>
        </div>
      </div>
    </div>

    <!-- END STATIC TEXT VIEWER -->

    <!-- TEI PUBLISHER -->

    <!-- <ng-container *ngFor="let doc of documents | keyvalue">
      <hr />
      <div
        class="row align-items-start"
        [id]="documents[doc.key].id.toString().toLowerCase()"
      >
        <div class="col-8">
          <h3 class="main-title">
            <i>{{ documents[doc.key].title.toString() }}</i>
          </h3>
        </div>
      </div>

      <div class="row align-items-start">
        <div class="col-12">
          <pb-page
            endpoint="https://teipublisher.com/exist/apps/tei-publisher"
            api-version="1.0.0"
          >
            <pb-document
              [path]="documents[doc.key].document1"
              odd="teipublisher"
              [id]="'document' + doc.key"
            >
            </pb-document>
            <pb-document
              [path]="documents[doc.key].document2"
              odd="teipublisher"
              [id]="'document' + (doc.key + 1)"
            >
            </pb-document>

            <app-drawer-layout force-narrow="force-narrow" narrow="">
              <app-header-layout>
                <app-header
                  fixed="fixed"
                  data-template="browse:fix-links"
                  style="
                    transition-duration: 0ms;
                    transform: translate3d(0px, 0px, 0px);
                    left: 0px;
                    right: 0.399994px;
                  "
                >
                  <app-toolbar
                    class="toolbar"
                    sticky="sticky"
                    style="transform: translate3d(0px, 0px, 0px)"
                  >
                    <pb-toggle-feature
                      name="highlight"
                      selector="tei-app,pb-popover,pb-highlight"
                      default="off"
                      action="disable"
                      emit="transcription"
                      subscribe="transcription"
                    >
                      <pb-i18n key="document.plain">Plain Reading View</pb-i18n>
                    </pb-toggle-feature>
                    <pb-zoom
                      emit="transcription"
                      direction="in"
                      icon="icons:zoom-in"
                    ></pb-zoom>
                    <pb-zoom
                      emit="transcription"
                      direction="out"
                      icon="icons:zoom-out"
                    ></pb-zoom>
                    <pb-navigation
                      emit="transcription"
                      keyboard="left"
                      subscribe="transcription"
                      direction="backward"
                      unit="page"
                    >
                      <paper-icon-button
                        icon="icons:chevron-left"
                        role="button"
                        tabindex="0"
                        aria-disabled="false"
                      ></paper-icon-button>
                    </pb-navigation>
                    <pb-navigation
                      emit="transcription"
                      keyboard="right"
                      subscribe="transcription"
                      direction="forward"
                      unit="page"
                    >
                      <paper-icon-button
                        icon="icons:chevron-right"
                        role="button"
                        tabindex="0"
                        aria-disabled="false"
                      ></paper-icon-button>
                    </pb-navigation>

                    <pb-progress
                      subscribe="transcription"
                      indeterminate=""
                      bottom-item="bottom-item"
                      style="visibility: hidden"
                    ></pb-progress>
                  </app-toolbar>
                </app-header>

                <main class="content-body">
                  <pb-view
                    [id]="'view' + doc.key"
                    [src]="'document' + doc.key"
                    [xpath]="documents[doc.key].xpath1"
                    subscribe="transcription"
                    emit="transcription"
                  ></pb-view>
                  <pb-view
                    [id]="'view' + (doc.key + 1)"
                    [src]="'document' + (doc.key + 1)"
                    [xpath]="documents[doc.key].xpath2"
                    subscribe="transcription"
                    emit="letter"
                  >
                    <pb-param name="mode" value="commentary"></pb-param>
                  </pb-view>
                </main>
              </app-header-layout>
            </app-drawer-layout>
          </pb-page>
        </div>
      </div>
    </ng-container> -->

    <!-- END TEI PUBLISHER -->
  </div>
</div>
