<!-- <div class="map-container">
  <div class="map-frame">
    <div id="map"></div>
  </div>
</div> -->
<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
</ng-container>

<!-- <ng-container *ngIf="mapData | async"> -->
  <div *ngIf="data" class="map-container">
    <div class="map-frame" [id]="data.containerId"></div>
  </div>
<!-- </ng-container> -->


