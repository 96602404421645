<div class="jumbotron">
  <div class="container">
    <h1 class="display-4">De Rerum Natura Online</h1>
    <!-- <p class="lead"><i>De rerum natura</i> in the contemporary art.</p> -->
    <hr class="my-4" />
    <p class="lead">
      <a class="btn btn-outline-light btn-lg" href="/#work" role="button"
        >Guarda le opere</a
      >
    </p>
  </div>
</div>
<div class="container">
  <div class="row justify-content-md-center">
    <div class="col-md-auto">
      <div class="description">
        <p>
          Questo progetto si propone di offrire al pubblico una mostra digitale interattiva a
          partire dalla ricezione del <i>De rerum natura</i> di Lucrezio nell'arte
          contemporanea. In particolare, le opere e le collezioni qui presentate
          sono state selezionate ed esposte a
          <a
            class="link"
            href="https://eventi.unibo.it/riscoperta-lucrezio/mostra-vedere-invisibile"
            ><i>Vedere l'Invisibile</i></a
          >, mostra tenutasi a Bologna nel 2017.
        </p>
        <p>
          La mostra digitale offre <a class="link" href="/#stories">percorsi interattivi</a> e <a class="link" href="/#timeline">viste multiple</a> per
          scoprire una ricca collezione riguardante la ricezione di Lucrezio
          nell'arte contemporanea.
        </p>
        <p>
          Infine, una selezione di diverse <a class="link" href="/#library">trascrizioni poetiche</a> italiane del
          <i>De rerum natura</i> è qui presentata per guidare il visitatore
          attraverso le opere d'arte presentate nella collezione.
        </p>
        <!-- <p>
          A sample of fifteen items, representatives of different media, has been
          selected in order to populate the digital collection. In addition to the
          gallery, an excerpt of the sixth book of De rerum natura is presented,
          along with William Ellery Leonard's translation. Finally, a preview of an
          interactive path based on the episode of the plague of Athens is presented
          to the visitor.
        </p> -->
      </div>
    </div>
  </div>
</div>
<div class="container-fluid sets-bg">
  <div class="container card-container">
    <div class="row">
      <div class="col">
        <h3 class="collection-title">Scopri le storie</h3>
      </div>
    </div>
    <div class="row">
      <div
        *ngFor="let story of loadedStories | slice : 0 : 3"
        class="col-md-4 col-sm-12 d-flex"
      >
        <div
          class="card bg-dark text-white border-0"
          style="width: 100%; max-height: 120px; margin-bottom: 10px"
        >
          <a
            [routerLink]="['/story', story['o:id'], slugify(story['o:title'])]"
            class="set-link"
            style="height: 100%"
          >
            <img
              [src]="story.thumbnail_display_urls.large"
              class="card-img"
              alt="..."
              style="height: 100%"
            />
            <div class="card-img-overlay d-flex">
              <h5 class="card-title align-self-center mx-auto">
                {{ story["o:title"] }}
              </h5>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
